import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import Fallback from "./components/extra/Fallback";

const Main = lazy(() => import("./main"));

const SuspensedMain = () => (
  <Suspense fallback={<Fallback />}>
    <Main />
  </Suspense>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<SuspensedMain />);
