import Lottie from "lottie-react";
import React from "react";
import fallback from "../../assets/lottie/fallback.json";

const Fallback = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Lottie animationData={fallback} style={{ width: 150 }} />
    </div>
  );
};

export default Fallback;
